import defaultSettings from '../settings.json';
const initialState = {
  settings: defaultSettings,
  userInfo: {
    permissions: {},
    username: ''
  }
};
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return {
          ...state,
          settings
        };
      }
    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        return {
          ...state,
          userLoading,
          userInfo
        };
      }
    default:
      return state;
  }
}