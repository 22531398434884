import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
export function getToken() {
  return localStorage.getItem('userToken');
}
export const handleError = error => {
  if (error.response.status === 401) {
    _Message.info('登录超时, 请重新登录');
    localStorage.clear();
    // 延迟关闭提示消息
    setTimeout(() => {
      _Message.clear(); // 清除提示消息
      window.location.pathname = '/login';
    }, 1000); // 设置延迟时间（单位：毫秒）
  } else if (error.response.status === 409) {
    _Message.loading({
      content: error.response.data.message,
      duration: 1000 // 设置显示时间为 1 秒
    });
    setTimeout(() => {
      _Message.success({
        content: 'reload success',
        duration: 1000 // 设置显示时间为 1 秒
      }); // 清除提示消息
    }, 1000); // 设置延迟时间（单位：毫秒）
  } else {
    _Message.error(error.message);
  }
};