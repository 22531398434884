import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'menu.tenant',
  key: 'tenant',
  children: [{
    name: 'menu.tenant.tenantInfo',
    key: 'tenant/tenantInfo'
  }, {
    name: 'menu.tenant.consoleInstanceInfo',
    key: 'tenant/consoleInstanceInfo'
  }, {
    name: 'menu.tenant.botInstanceInfo',
    key: 'tenant/botInstanceInfo'
  }, {
    name: 'menu.tenant.botSupportCase',
    key: 'tenant/botSupportCase'
  }, {
    name: 'menu.tenant.apiAuth',
    key: 'tenant/apiAuth'
  }]
}, {
  name: 'menu.version',
  key: 'version',
  children: [{
    name: 'menu.version.botVersion',
    key: 'version/botVersion'
  }, {
    name: 'menu.version.consoleVersion',
    key: 'version/consoleVersion'
  }]
}, {
  name: 'menu.parameter',
  key: 'parameter',
  children: [{
    name: 'menu.parameter.parameterSchema',
    key: 'parameter/parameterSchema'
  }, {
    name: 'menu.parameter.parameterCurrencyDefaultValue',
    key: 'parameter/parameterCurrencyDefaultValue'
  }, {
    name: 'menu.parameter.parameterBotDefaultValue',
    key: 'parameter/parameterBotDefaultValue'
  }]
}, {
  name: 'menu.config',
  key: 'config',
  children: [{
    name: 'menu.config.botConfig',
    key: 'config/botConfig'
  }]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};
const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};
export default useRoute;