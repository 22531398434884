import Mock from 'mockjs';
import { isSSR } from '@/utils/is';
import setupMock from '@/utils/setupMock';
if (!isSSR) {
  Mock.XHR.prototype.withCredentials = true;
  setupMock({
    setup: () => {
      // 用户信息
      // const userRole = window.localStorage.getItem('userRole') || 'admin';
      // Mock.mock(new RegExp('/api/user/userInfo'), () => {
      //   return Mock.mock({
      //     name: '王立群',
      //     avatar:
      //       'https://lf1-xgcdn-tos.pstatp.com/obj/vcloud/vadmin/start.8e0e4855ee346a46ccff8ff3e24db27b.png',
      //     email: 'wangliqun@email.com',
      //     job: 'frontend',
      //     jobName: '前端开发工程师',
      //     organization: 'Frontend',
      //     organizationName: '前端',
      //     location: 'beijing',
      //     locationName: '北京',
      //     introduction: '王力群并非是一个真实存在的人。',
      //     personalWebsite: 'https://www.arco.design',
      //     verified: true,
      //     phoneNumber: /177[*]{6}[0-9]{2}/,
      //     accountId: /[a-z]{4}[-][0-9]{8}/,
      //     registrationTime: Mock.Random.datetime('yyyy-MM-dd HH:mm:ss'),
      //     permissions: generatePermission(userRole),
      //   });
      // });

      // 登录
      // Mock.mock(new RegExp('/api/user/login'), (params) => {
      //   const { username, password } = JSON.parse(params.body);
      //   if (!username) {
      //     return {
      //       succ: false,
      //       code: -1,
      //       message: '用户名不能为空',
      //       value: null,
      //     };
      //   }
      //   if (!password) {
      //     return {
      //       succ: false,
      //       code: -1,
      //       message: '密码不能为空',
      //       value: null,
      //     };
      //   }
      //   if (username === 'admin' && password === '123456') {
      //     return {
      //       succ: true,
      //       code: 0,
      //       message: '登录成功',
      //       value: {
      //         userId: 1,
      //         email: 'wangliqun@email.com',
      //         username: '王立群',
      //         token: 'fakeToken',
      //       },
      //     };
      //   }
      //   return {
      //     succ: false,
      //     code: -1,
      //     message: '账号或者密码错误',
      //     value: null,
      //   };
      // });
    }
  });
}